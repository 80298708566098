<template>
  <div>
    <!-- 打印时选择车辆，强训日期 考场 -->
    <div id="myDiv" style="width:273px;text-align:center;opacity:0;position:absolute;">
      <div style="padding:0px 0 0 10px;">
         <div v-for="(item,index) in stu" :key="index" style="margin-top:0px;">
          <div style="font-size:18px;margin-bottom:15px">{{item.billTitle}}</div>
          <div style="font-size:14px;margin-bottom:5px">
            单<span style="opacity:0">哈哈</span>号：{{item.billNo}}
          </div>
          <div style="font-size:14px;margin-bottom:5px">
            姓<span style="opacity:0">哈哈</span>名：{{item.name}}
          </div>
          <div style="font-size:14px;margin-bottom:5px">身份证号：{{idCard}}</div>
          <div style="font-size:14px;margin-bottom:5px">强训日期：{{item.practiceDate}}</div>
          <div style="font-size:14px;margin-bottom:5px">车<span style="opacity:0">哈哈</span>号：{{item.car}}</div>
          <div style="font-size:14px;margin-bottom:5px">
            数<span style="opacity:0">哈哈</span>量：{{item.turn}}
            <!--{{item.subjectId==2?'课时':'圈'}}-->
          </div>
          <div style="font-size:14px;margin-bottom:5px">
            套餐名称：{{item.hotMealName}}
            <!--{{item.subjectId==2?'课时':'圈'}}-->
          </div>
          <!-- <div style="font-size:14px;margin-bottom:5px">
            驾<span style="opacity:0">哈哈</span>校：{{item.school}}
          </div> -->
          <div style="font-size:14px;margin-bottom:5px">
            备<span style="opacity:0">哈哈</span>注：{{item.remark}}
          </div>
          <div style="font-size:14px;margin-bottom:5px">
            打印时间：{{time}}
          </div>
          <div style="width:200px;height:180px;overflow:hidden;display:none">
            <img :src="item.qrcode" alt="" style="width:260px;height:auto;margin-top:-40px;margin-left:-45px">
          </div>
          <div style="display:flex;font-size:14px;width:213px;">
            <div style="width:50%;display:inline-block;">
              <span style="float:left;">学员签字</span>
              <span style="width:35px;float:left;height:19px;display:inline-block;border-bottom:solid 1px rgba(0,0,0,0.5)"></span>
            </div>
            <div style="width:50%;display:inline-block;">
              <span style="float:left;">教练签字</span>
              <span style="width:35px;float:left;height:19px;display:inline-block;border-bottom:solid 1px rgba(0,0,0,0.5)"></span>
            </div>
          </div>
          <!-- border-top:dashed 1px rgba(0,0,0,0.5);margin-top:15px; -->
          <div  style="text-align:left;width:220px;padding:10px 0 30px;font-size:12px;border-bottom:solid 1px rgba(0,0,0,0.1)">
            提示：此票仅为练车凭证，不承诺包过.
          </div>
        </div> 
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: "SjInput",
  props: {
    stu: {
      type: Array,
      default: []
    },
    
  },
  data() {
    return{
      visiable:true,
      time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      idCard:''
    }
  },
  watch: {
    
  },
  methods: {
    moment,
    printTicket(){
      var printHtml = document.getElementById("myDiv").innerHTML;
      var wind = window.open("",'newwindow', 'height=700, width=700, top=100, left=100, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no');
      wind.document.body.innerHTML = printHtml;
      wind.print();
      this.setPrintTimes()
      return false;
    },
    closePrint(){
      this.$parent.colsePrint()
    },
    setPrintTimes(){
      if(!this.stu[0].hotFeeId)return;
      this.$post(this.$url.强训打印次数, {
        hotFeeId:this.stu[0].hotFeeId,
      }).then(data => {
      });
    }
  },
  mounted() {
    setTimeout(()=>{
      this.printTicket()
    },200)
    this.idCard = this.stu[0].idCard.substring(0,4) + '**********' + this.stu[0].idCard.substring(13,17)
  }
};
</script>

<style scoped>



</style>
