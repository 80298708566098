<template>
  <div>
    <div class="add-containe">
      <div class="flex">
        <label for="" class="info-title">考试日期：</label>
        <a-date-picker
          class="flex1"
          v-model="add.examDay"
          format="YYYY-MM-DD"
        />
      </div>
      <div class="flex">
        <label for="" class="info-title">强训日期：</label>
        <a-date-picker
          class="flex1"
          v-model="add.practiceDay"
          format="YYYY-MM-DD"
        />
      </div>
      <getCard @card="getCardKey" v-if="showGetCard" />
      <div class="flex">
        <label for="" class="info-title">身份证号：</label>
        <!-- <a-input v-model="add.idCard" class="flex1" type="text" :maxLength="18"></a-input> -->
        <div
          class="flex1"
          style="position: relative; width: 430px; height: 35px;"
        >
          <a-input
            v-model.trim="vagueCard"
            @change="cardChg"
            placeholder="身份证"
            :maxLength="18"
            :style="{ opacity: 1 }"
            style="width: 430px; margin-left: 0px;"
          ></a-input>
          <div
            style="
              position: absolute;
              z-index: 2;
              top: 30px;
              left: 0px;
              right: 21px;
              background: #fff;
              box-shadow: 0 0 5px #aaa;
              max-height: 300px;
              overflow-y: scroll;
            "
          >
            <div
              v-for="(d, index) in stuList"
              :key="index + d.idCard"
              style="
                border-bottom: solid 1px #ddd;
                padding: 0 10px;
                cursor: pointer;
              "
              @click="sureCard(d)"
            >
              {{ d.idCard }}
              <span style="margin-left: 20px;">{{ d.studentName }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <label for="" class="info-title">学员姓名：</label>
        <a-input
          v-model="add.studentName"
          class="flex1"
          type="text"
          max="100"
          :maxLength="3"
        ></a-input>
      </div>
      <div class="flex">
        <label for="" class="info-title">
          车
          <span style="opacity: 0;">哈哈</span>
          型：
        </label>
        <a-select v-model="add.licName" class="flex1" @change="getCarList()">
          <a-select-option value="C1">
            C1
          </a-select-option>
          <a-select-option value="C2">
            C2
          </a-select-option>
          <a-select-option value="A1">
            A1
          </a-select-option>
          <a-select-option value="A2">
            A2
          </a-select-option>
          <a-select-option value="A3">
            A3
          </a-select-option>
          <a-select-option value="D">
            D
          </a-select-option>
          <a-select-option value="E">
            E
          </a-select-option>
          <a-select-option value="F">
            F
          </a-select-option>
        </a-select>
      </div>
      <div class="flex">
        <label for="" class="info-title">车辆编号：</label>
        <a-select v-model="add.carHotCode" class="flex1">
          <a-select-option v-for="car in carList" :key="car.carHotCode">
            {{ car.carHotCode }}号 - {{ car.totalPracticeNum }}
          </a-select-option>
        </a-select>
      </div>
      <div class="flex">
        <label for="" class="info-title">业务员名：</label>
        <!-- <a-input v-model="add.hotAgentName" class="flex1" type="text" :maxLength="5" placeholder="业务员"></a-input> -->
        <a-select show-search v-model="add.hotAgentName" class="flex1">
          <a-select-option
            v-for="sale in saleList"
            :key="sale.id"
            :value="sale.hotAgentName"
          >
            {{ sale.hotAgentName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="flex">
        <label for="" class="info-title">强训套餐：</label>
        <a-select v-model="add.hotId" class="flex1" @change="changeStand">
          <a-select-option :value="tc.id" v-for="tc in stardList" :key="tc.id">
            {{ tc.trainType }}
          </a-select-option>
        </a-select>
      </div>
      <div class="flex">
        <div class="flex1">
          <label for="" class="info-title">数量：</label>
          {{ add.practiceNum }}
        </div>
        <div class="flex1">
          <label for="" class="info-title">金额：</label>
          ￥{{ add.amount }}
        </div>
      </div>
      <div class="flex">
        <label for="" class="info-title">支付方式：</label>
        <div class="flex1">
          <a-radio-group
            v-model="add.chargeWay"
            button-style="solid"
            class="flex"
            style="margin: 0; text-align: center;"
          >
            <a-radio-button value="微信" class="flex1">
              微信
            </a-radio-button>
            <a-radio-button value="支付宝" class="flex1">
              支付宝
            </a-radio-button>
            <a-radio-button value="现金" class="flex1">
              现金
            </a-radio-button>
            <a-radio-button value="POS机" class="flex1">
              POS机
            </a-radio-button>
            <a-radio-button value="转账" class="flex1">
              转账
            </a-radio-button>
          </a-radio-group>
        </div>
      </div>
      <div class="flex">
        <label for="" class="info-title">备注信息：</label>
        <a-input
          v-model="add.remark"
          class="flex1"
          type="textarea"
          max="100"
          :maxLength="200"
        ></a-input>
      </div>

      <div class="act-con">
        <a-checkbox v-model="isPrint">
          是否自动打印小票？
        </a-checkbox>
        <a-button v-if="edit" class="dis" @click="clearInfo">清除</a-button>
        <a-button type="primary" @click="addMethod">确定</a-button>
      </div>
    </div>
    <div class="exam-record">
      <h3>考试记录</h3>
      <table class="my-table">
        <tr>
          <th>身份证</th>
          <th>考试日期</th>
          <th>次数</th>
          <th>训练数量</th>
        </tr>
        <tr v-for="item in hadTrainList" :key="item.chargeTime">
          <td>{{ item.idCard }}</td>
          <td>{{ item.examDay }}</td>
          <td>{{ item.totalTimes }}</td>
          <td>{{ item.totalPracticeNum }}</td>
        </tr>
      </table>
    </div>
    <a-modal
      :visible="showSuccess"
      title="学员添加成功"
      okText="确定"
      cancelText="取消"
      @ok="surePrint"
      @cancel="
        showSuccess = false
        surePrint()
      "
    >
      <div>
        <span class="stuname">{{ add.studentName }}</span>
        学员强训添加成功
        <br />
      </div>
      <a-checkbox v-model="isPrint">
        是否自动打印小票？
      </a-checkbox>
    </a-modal>
    <!-- 打印小票 -->
    <print-ticket :stu="stu" v-if="showPrint" />
  </div>
</template>
<script>
import getCard from '../../components/GetCard'
import printTicket from '../../components/PrintTicket'
export default {
  components: {
    getCard,
    printTicket,
  },
  data() {
    return {
      add: {
        chargeWay: '微信',
      },
      edit: true,
      carList: [],
      stardList: [],
      filter: {
        cdHadHotTrain: '',
        cdOutIdCardOnly: false,
        examSpotId: this.$store.state.curSpotId,
      },
      vagueCard: '',
      stuList: [],
      hadTrainList: [],
      saleList: [],
      stu: [],
      isPrint: true,
      showPrint: false,
      showSuccess: false,
      billNo: '',
      timer: null,
      showGetCard: true,
    }
  },
  mounted() {},
  methods: {
    clearInfo() {
      this.add.studentName = ''
      this.add.idCard = ''
      this.add.carHotCode = ''
      this.add.remark = ''
      this.vagueCard = ''
    },
    getCardKey(info) {
      this.vagueCard = info.card
      this.add.idCard = info.card
      this.searchRecord()
      this.add.studentName = info.name
    },
    getSaleList() {
      this.$post(this.$url.业务员列表, {
        examSpotId: this.$store.state.curSpotId,
      })
        .then((data) => {
          this.saleList = data.data.list
        })
        .catch(() => {})
    },
    cardChg() {
      if (this.vagueCard.length > 10) {
        this.showGetCard = false
      } else if (!this.vagueCard) {
        this.showGetCard = true
      }
      this.add.idCard = this.vagueCard
      this.searchRecord()
      let search = this.debounce(this.getStuList, 500)
    },
    debounce(func, wait) {
      let _this = this
      return function () {
        let context = this // 注意 this 指向
        let args = arguments // arguments中存着e
        if (_this.timer) clearTimeout(_this.timer)
        _this.timer = setTimeout(() => {
          func.apply(this, args)
        }, wait)
      }
    },
    sureCard(item) {
      this.stuList = []
      this.vagueCard = item.idCard
      this.add.idCard = item.idCard
      this.showGetCard = false
      this.searchRecord()
      this.add.studentName = item.studentName
      this.add.licName = item.licName
      this.getCarList()
    },
    searchRecord() {
      if (this.add.examDay)
        this.filter.cdExamDay = this.$moment(this.add.examDay).format(
          'YYYY-MM-DD',
        )
      this.filter.cdIdCard = this.vagueCard
      if (this.vagueCard.length < 10) return
      this.$post(this.$url.强训记录查询, {
        examSpotId: this.$store.state.curSpotId,
        idCard: this.vagueCard,
        examDay: this.filter.cdExamDay,
      })
        .then((data) => {
          this.hadTrainList = data.data.list
        })
        .catch(() => {})
    },
    //预约学员列表
    getStuList() {
      this.filter.cdIdCard = this.vagueCard
      if (this.add.examDay)
        this.filter.cdExamDay = this.$moment(this.add.examDay).format(
          'YYYY-MM-DD',
        )
      this.$post(this.$url.预约学员列表, this.filter)
        .then((data) => {
          this.stuList = data.data.list
          if (!this.stuList.length) {
            this.add.idCard = this.vagueCard
            this.searchRecord()
            this.getCarList()
          }
        })
        .catch(() => {})
    },
    changeStand(a) {
      this.stardList.forEach((tc) => {
        if (tc.id == a) {
          this.add.hotMealName = tc.trainType
          this.add.practiceNum = tc.trainNum
          this.add.amount = tc.trainPrice
        }
      })
    },
    getStardList() {
      this.$post(this.$url.强训套餐列表, {
        examSpotId: this.$store.state.curSpotId,
      })
        .then((data) => {
          this.stardList = data.data.list
        })
        .catch(() => {})
    },
    getCarList() {
      if (!this.add.examDay) return
      let date = this.$moment(this.add.examDay).format('YYYY-MM-DD')
      if (!this.add.licName) return
      this.$post(this.$url.查询考车, {
        examSpotId: this.$store.state.curSpotId,
        cdExamDay: date,
        cdLicName: this.add.licName,
      }).then((data) => {
        this.carList = data.data.list
      })
    },
    addMethod() {
      if (!this.add.studentName) {
        this.$warning({
          title: '警告',
          content: '请输入强训学员姓名',
        })
        return
      }
      //处理性别
      if (!this.add.idCard) {
        this.$warning({
          title: '警告',
          content: '请检查身份证号！',
        })
        return
      } else {
        let n = this.add.idCard.split(16, 1)
        if (n % 2) {
          this.add.gender = '男'
        } else {
          this.add.gender = '女'
        }
      }
      if (this.add.examDay)
        this.add.examDay = this.$moment(this.add.examDay).format('YYYY-MM-DD')
      if (this.add.practiceDay)
        this.add.practiceDay = this.$moment(this.add.practiceDay).format(
          'YYYY-MM-DD',
        )
      this.add.examSpotId = this.$store.state.curSpotId
      this.addData()
    },
    addData() {
      this.showGetCard = false
      this.$post(this.$url.添加强训学员, this.add)
        .then((data) => {
          this.filter = {
            cdHadHotTrain: '',
            cdOutIdCardOnly: false,
            examSpotId: this.$store.state.curSpotId,
          }
          this.billNo = data.data.billNo
          //this.showSuccess = true;
          this.surePrint()
          this.showGetCard = true
        })
        .catch(() => {})
    },
    surePrint() {
      if (this.isPrint) {
        this.printStu(this.add, this.billNo)
      } else {
        this.add.studentName = ''
        this.add.idCard = ''
        this.add.carHotCode = ''
        this.add.remark = ''
        this.vagueCard = ''
      }
      this.showSuccess = false
    },
    printStu(item, billNo) {
      // this.stu.push({
      //   billNo: billNo,
      //   billTitle: this.$store.state.billNo,
      //   name: item.studentName,
      //   idCard: item.idCard,
      //   practiceDate: item.practiceDay,
      //   car: item.carHotCode,
      //   turn: item.practiceNum,
      //   school: '',
      //   remark: item.remark,
      //   hotMealName: item.hotMealName,
      // })
      this.stu = [
        {
          billNo: billNo,
          billTitle: this.$store.state.billNo,
          name: item.studentName,
          idCard: item.idCard,
          practiceDate: item.practiceDay,
          car: item.carHotCode,
          turn: item.practiceNum,
          school: '',
          remark: item.remark,
          hotMealName: item.hotMealName,
        },
      ]
      this.showPrint = true
      setTimeout(() => {
        this.showPrint = false
      }, 5000)
      //清空数据
      this.add.studentName = ''
      this.add.idCard = ''
      this.add.carHotCode = ''
      this.add.remark = ''
      this.vagueCard = ''
    },
  },
  activated() {
    this.getStardList()
    this.getSaleList()
    this.showGetCard = true
  },
  deactivated() {
    this.showGetCard = false
  },
}
</script>
<style scoped>
.stuname {
  font-size: 16px;
  font-weight: bold;
  margin-right: 8px;
  margin-bottom: 12px;
  display: inline-block;
}
.add-containe {
  max-width: 500px;
  width: 100%;
  margin: 0 20px;
  float: left;
}
.exam-record {
  padding-top: 20px;
}
.flex {
  margin: 15px 0;
  line-height: 30px;
}
.act-con {
  text-align: right;
}
.my-table {
  max-width: 500px;
  width: 100%;
}
</style>
